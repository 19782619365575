import { EnrolledProgram, MembershipType, User } from 'common/utils/types';
import { uniqueId } from 'lodash';

const baseEnrolledProgram: EnrolledProgram = {
  id: 1,
  slug: 'virtual-care-visit',
  on_demand: false,
  program_type: 'scheduled',
};

export const buildEnrolledProgram = (
  overrideProps: Partial<EnrolledProgram> = {},
) => ({
  ...baseEnrolledProgram,
  id: parseInt(uniqueId()),
  ...overrideProps,
});

const baseConsumerAttributes: User['consumer_attributes'] = {
  dob: '1990-01-01',
  gender: 'male',
  id: 1,
  wants_marketing: false,
  age: 32,
};

export const buildConsumerAttributes = (
  overrideProps: Partial<User['consumer_attributes']> = {},
) => ({
  ...baseConsumerAttributes,
  id: parseInt(uniqueId()),
  ...overrideProps,
});

const baseUser: User = {
  actions: null,
  age: 20,
  consumer_attributes: {},
  email: 'demo@everlyhealth.com',
  can_update_email: true,
  first_name: 'Demo',
  id: 1,
  last_name: 'Account',
  phone_number: '5016262956',
  ship_address: {
    address1: '123 Street',
    address2: '',
    city: 'Lakewood',
    phone: '123456',
    zipcode: '98496',
    state_id: 3577,
  },
  subscriptions: [],
  created_at: new Date(),
  ip_address: '',
  membership: {
    active_credits: 0,
    membership_type: MembershipType.Control,
    state: undefined,
  },
  enrolled_programs: [],
  credits: [{ status: 'any' }],
  next_credit_date: new Date().toISOString(),
};

export const buildUser = (props: Partial<User> = {}): User => ({
  ...baseUser,
  id: parseInt(uniqueId()),
  ...props,
});

export const buildConsumerUser = (overrideProps: Partial<User> = {}): User =>
  buildUser({
    ...overrideProps,
    consumer_attributes: buildConsumerAttributes({
      ...overrideProps.consumer_attributes,
    }),
  });

export const buildWeightCarePlusUser = (
  overrideProps: Partial<User> = {},
): User =>
  buildUser({
    user_plans: {
      enabled: [
        {
          id: 1,
          spree_variant_id: 1139,
          subscription_started_at: '2023-04-18T19:08:07.285Z',
          initial_order_id: 6520623,
          program_plan_id: 2,
          program_plan_type: 'weight_management',
          program_plan_name: 'Weight Management',
          status: 'active',
        },
      ],
      disabled: [],
    },
    ...overrideProps,
  });

export const buildAsyncUser = (overrideProps: Partial<User> = {}): User =>
  buildUser({
    enrolled_programs: [
      { id: 1, slug: 'bv-async', on_demand: false, program_type: 'async' },
    ],
    ...overrideProps,
  });

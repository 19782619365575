import { ToastType } from '@everlywell/leaves';
import { TestCollectionMethod } from 'components/KitCollection/model';
import { Map } from 'immutable';
import { ReactNode } from 'react';
import { ImmutableEntitiesState } from 'store/entities/types';
import { ImmutableAppState } from 'store/reducer';
import { ImmutableSessionState } from 'store/session/types';

export type ActionT = {
  type: string; // TODO: get union of all action types
  error?: string;
  payload?: any;
};

export interface AccordionSection {
  isOpen: boolean;
  label: string;
  expandIcon: string;
  closeIcon: string;
  content: string;
  clickAttributes?: any;
}

interface ReduxState {
  router: any;
  app: ImmutableAppState;
  entities: ImmutableEntitiesState;
  session: ImmutableSessionState;
}

export interface ImmutableReduxState extends Map<string, any> {
  get<K extends keyof ReduxState>(key: K, Default?: any): ReduxState[K];
}

export type SeverityResolution = {
  type: string;
  value: number;
};

export type KitResult = {
  id: number;
  needs_replacement: boolean;
  number: string;
  product_name: string;
  user_email: string;
  kit_result_time: string;
  status: string;
  first_name?: string;
  genomics_result_status?: string;
  test_id?: number;
  test?: Test;
  lab?: Lab;
  markers?: Marker[];
  marker_results?: MarkerResult[];
  severity_resolution?: number;
  severity_resolutions?: SeverityResolution[];
  publish_hash?: string;
  content: any;
  published_at?: string;
  kit_registered_at?: string;
  lab_received_at?: string;
  in_transit_at?: string;
  delivered_at?: string;
  results_approved_at?: string;
  requisition_approved_at?: string;
  replacement_requested_at?: string;
  box_image_url?: string;
  viewed_at?: string;
  min_process_time?: number;
  max_process_time?: number;
  consult?: Consult;
  live_webinar_available?: boolean;
  viewable?: boolean;
  paid?: boolean;
  tracking_url: string;
  product_slug: string;
  serial_number: number;
  pwn_confirmation_code?: number;
  pwn_order_number?: number;
  any_dnr_marker_results?: boolean;
  cancel_reason?: string;
  confirm_identifiers_issue_id?: number;
  is_lab_visit?: boolean;
  clia: string;
  inconclusive_results?: boolean;
  pretty_number?: string;
  marker_replacement?: MarkerReplacement;
  barcode_serial_number?: string;
};

export type MarkerReplacement = {
  status: string;
  replacement_product: string;
  replacement_result_number: string;
  requested_at: string;
  resulted_at: string;
};

export interface ImmutableKitResult extends Map<string, any> {
  get<K extends keyof KitResult>(key: K, Default?: KitResult): KitResult[K];
  find(
    predicate: (value: KitResult, key: keyof KitResult, iter: this) => boolean,
    context?: any,
    notSetValue?: any,
  ): KitResult | undefined;
  toJS(): KitResult;
}

export type Lab = {
  content_token: string;
  id: number;
  name: string;
  pwn_lab_identifier: string;
  ship_address_id: number;
};

export interface ImmutableLab extends Map<string, any> {
  get<K extends keyof Lab>(key: K, Default?: Lab): Lab[K];
  find(
    predicate: (value: Lab, key: keyof Lab, iter: this) => boolean,
    context?: any,
    notSetValue?: any,
  ): Lab | undefined;
  toJS(): Lab;
}

export interface Marker {
  content?: Content;
  content_token: string;
  descriptors: string[];
  groupings: Grouping[];
  id: number;
  max_value?: number;
  min_value?: number;
  name: string;
  severities: number[];
  severity_colors: string[];
  test_id: number;
  type: string;
  units?: string;
}

export interface Grouping {
  name: string;
  groupingType: string;
}

export interface MarkerWithResult extends Marker {
  marker_result: MarkerResult;
  order?: number;
}

export interface ImmutableMarker extends Map<string, any> {
  get<K extends keyof Marker>(key: K, Default?: Marker): Marker[K];
  find(
    predicate: (value: Marker, key: keyof Marker, iter: this) => boolean,
    context?: any,
    notSetValue?: any,
  ): Marker | undefined;
  toJS(): Marker;
}

export type FormModalType = 'error' | 'warning' | 'info' | 'redirect';

export type MarkerResult = {
  boundaries: number[];
  boundary_conditions: string[];
  content_token: string;
  descriptor: string;
  id: number;
  marker_id: number;
  observation_datetime?: string | null;
  reported_at?: string;
  results_approved_at?: string;
  severity_index: number;
  test_result_id: number;
  value: number | string;
  historical_results?: HistoricalResult[];
  kind?: string;
};

export type HistoricalResult = {
  id: number;
  marker_id: number;
  value: number | string;
  severity_index: number;
  descriptor: string;
  test_result_id: number;
  boundaries: number[];
  reported_at?: string;
  results_approved_at: string;
  collected_at: string;
  boundary_conditions: string[];
};

export interface ImmutableMarkerResult extends Map<string, any> {
  get<K extends keyof MarkerResult>(
    key: K,
    Default?: MarkerResult,
  ): MarkerResult[K];
  find(
    predicate: (
      value: MarkerResult,
      key: keyof MarkerResult,
      iter: this,
    ) => boolean,
    context?: any,
    notSetValue?: any,
  ): MarkerResult | undefined;
  toJS(): MarkerResult;
}

export type Test = {
  content_token: string;
  content?: Content;
  display_name: string;
  genomics_eligible?: boolean;
  id?: number;
  marker_types?: string[];
  name?: string;
  type: string;
  is_food_sensitivity?: boolean;
  recorded_webinar: boolean;
  theme_icon: string;
};
export interface ImmutableTest extends Map<string, any> {
  get<K extends keyof Test>(key: K, Default?: Test): Test[K];
  find(
    predicate: (value: Test, key: keyof Test, iter: this) => boolean,
    context?: any,
    notSetValue?: any,
  ): Test | undefined;
  toJS(): Test;
}

export type ContentSnippet = {
  OVERVIEW_DESCRIPTION?: string;
  token?: string;
  id?: number;
  key?: string;
  body?: string;
};

export interface ImmutableContentSnippet extends Map<string, any> {
  get<K extends keyof ContentSnippet>(
    key: K,
    Default?: ContentSnippet,
  ): ContentSnippet[K];
  find(
    predicate: (
      value: ContentSnippet,
      key: keyof ContentSnippet,
      iter: this,
    ) => boolean,
    context?: any,
    notSetValue?: any,
  ): ContentSnippet | undefined;
  toJS(): ContentSnippet;
}

export type FoodSensitivityGroup = {
  title: string;
  groupedMarkers: MarkerResult[];
  includedSeverityIndexes?: number[];
};

export enum MembershipType {
  Control = 'control',
  STI = 'sti',
}

export enum MembershipStatus {
  Active = 'active',
  Archived = 'archived',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Paused = 'paused',
}

export const ActiveMembershipStatuses = [
  MembershipStatus.Active,
  MembershipStatus.Failed,
  MembershipStatus.Paused,
];

// TODO: update the types when we need them, subscriptions, actions, etc.
type ShipAddress = {
  address1?: string;
  address2?: string;
  city?: string;
  phone?: string;
  zipcode?: string;
  state_id?: number;
};

//#TODO: Fix based on the new API
export const FORM_TYPE = {
  demographics: 'demographics',
  'medical-conditions': 'medical-conditions',
  'medical-intake': 'medical-intake',
  payment: 'payment',
  pharmacy: 'pharmacy',
  'photo-document-upload': 'photo-document-upload',
};

export const PROGRAM_TYPE = {
  'bv-async': 'bv-async',
  'cold-sores-async': 'cold-sores-async',
  'covid-virtual-care-visit': 'covid-virtual-care-visit',
  enterprise: 'enterprise',
  'everlywell-plus': 'everlywell-plus',
  'flu-virtual-care-visit': 'flu-virtual-care-visit',
  'genital-herpes-async': 'genital-herpes-async',
  'mens-health-virtual-care-visit': 'mens-health-virtual-care-visit',
  'sti-on-demand': 'sti-on-demand',
  'sti-virtual-care-visit': 'sti-virtual-care-visit',
  'thyroid-virtual-care-visit': 'thyroid-virtual-care-visit',
  'uti-async': 'uti-async',
  'uti-virtual-care-visit': 'uti-virtual-care-visit',
  'virtual-care-visit': 'virtual-care-visit',
  'weight-care-ongoing': 'weight-care-ongoing',
  'weight-care-ongoing-async': 'weight-care-ongoing-async',
  'weight-management': 'weight-management',
  'weight-management-full': 'weight-management-full',
  'womens-health-virtual-care-visit': 'womens-health-virtual-care-visit',
  'yeast-infection-async': 'yeast-infection-async',
} as const;

export type FormType = keyof typeof FORM_TYPE;
export type ProgramType = keyof typeof PROGRAM_TYPE;

export const ProgramDisplayNames: Record<ProgramType, string> = {
  'bv-async': 'Bacterial Vaginosis Online Review',
  'cold-sores-async': 'Cold Sores Online Review',
  'covid-virtual-care-visit': 'COVID-19 Virtual Care',
  'everlywell-plus': 'Everlywell+',
  'flu-virtual-care-visit': 'Flu Virtual Care',
  'genital-herpes-async': 'Genital Herpes Online Review',
  'mens-health-virtual-care-visit': 'Men’s Health Virtual Care',
  'sti-on-demand': 'STI & STD',
  'sti-virtual-care-visit': 'STI & STD',
  'thyroid-virtual-care-visit': 'Thyroid Virtual Care',
  'uti-async': 'UTI Online Review',
  'uti-virtual-care-visit': 'UTI Virtual Care',
  'virtual-care-visit': 'General Wellness',
  'weight-care-ongoing': 'Weight Care+',
  'weight-care-ongoing-async': 'Weight Care+',
  'weight-management': 'Weight Care+',
  'weight-management-full': 'Weight Care+',
  'womens-health-virtual-care-visit': 'Women’s Health Virtual Care',
  'yeast-infection-async': 'Yeast Infection Online Review',
  enterprise: 'Test to treatment',
};

export type MembershipProgram = 'weight_management' | 'control';

export const MEMBERSHIP_PROGRAMS_LIST: MembershipProgram[] = [
  'weight_management',
  'control',
];

export const ProgramSlugToPlanType: Record<string, MembershipProgram> = {
  'weight-management-full': 'weight_management',
  'everlywell-plus': 'control',
};

export type ProgramsAtCheckout =
  | Extract<
      ProgramType,
      'weight-management-full' | 'everlywell-plus' | 'weight-management'
    >
  | (string & {});

export const VCV_PROGRAMS: ProgramType[] = [
  PROGRAM_TYPE['virtual-care-visit'],
  PROGRAM_TYPE['sti-virtual-care-visit'],
  PROGRAM_TYPE['sti-on-demand'],
  PROGRAM_TYPE['uti-virtual-care-visit'],
  PROGRAM_TYPE['mens-health-virtual-care-visit'],
  PROGRAM_TYPE['womens-health-virtual-care-visit'],
  PROGRAM_TYPE['thyroid-virtual-care-visit'],
  PROGRAM_TYPE['covid-virtual-care-visit'],
  PROGRAM_TYPE['flu-virtual-care-visit'],
];

export type EnrolledProgram = {
  id: number;
  slug: ProgramType;
  on_demand: boolean;
  program_type: 'async' | 'scheduled' | 'on_demand';
};

type Marketing = {
  label: string | undefined;
  partner_user_id: string;
  tag_name: string | undefined;
};

type UserPlan = {
  id: number;
  spree_variant_id: number;
  subscription_started_at: string;
  initial_order_id: number;
  program_plan_id: number;
  program_plan_type: MembershipProgram;
  program_plan_name: string;
  status: string;
  marketing?: Marketing;
};

export type SubscriptionType = {
  state: MembershipStatus;
  id: number;
  frequency: string;
  order_number: string;
};

export type User = {
  actions: any;
  age: number;
  consumer_attributes: ConsumerAttributes | {};
  email: string;
  can_update_email: boolean;
  first_name: string;
  id: number;
  last_name: string;
  phone_number: string;
  ship_address: ShipAddress | null;
  subscriptions: SubscriptionType[];
  created_at: Date;
  ip_address: string;
  membership: {
    active_credits: number;
    membership_type?: MembershipType;
    state?: MembershipStatus;
  };
  credits: { status: string }[];
  next_credit_date: string;
  full_user_data?: boolean;
  enrolled_programs: EnrolledProgram[];
  health_provider_user_state?: string | null; // temporary property, will be updated soon as we finish CP1-323
  user_plans?: { enabled: UserPlan[]; disabled: UserPlan[] };
  enterprise_attributes?: EnterpriseAttributes;
};

export type ConsumerAttributes = {
  id: number;
  // format: yyyy-MM-dd
  dob: string;
  gender: 'male' | 'female';
  wants_marketing: boolean;
  age: number;
};

export type EnterpriseAttributes = {
  partner_slug?: string | null;
  enterprise_telehealth_program_slug?: string | null;
  test_to_treat_program_slug?: string | null;
};

export type AddressSuggestion = {
  street: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
};

export type AddressDetails = {
  street: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
};

export type KitIssue = {
  identifiers_to_confirm: string[];
  minimum_age: number;
};

export type KitIssuePostAction = {
  post_action: string;
};

export type PhysicianContact = {
  firstName?: string;
  lastName?: string;
  practiceName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  physicianPhone: string;
};

export type Content = {
  [index: string]: string;
};

export interface MarkerContent {
  markerOverview: string;
}

export interface NonFSMarkerContent extends MarkerContent {
  markerTellMeMore: string;
}

export interface FsMarkerContent extends MarkerContent {
  abnormalReactivity?: string;
  hiddenSources?: string;
  crossReactivites?: string;
}

export interface IntensityMarkerContent extends MarkerContent {
  abnormalReactivity?: string;
  markerOverviewHeader?: string;
  markerOverviewExtendedContent1Header?: string;
  markerOverviewExtendedContent1?: string;
  markerOverviewExtendedContent2Header?: string;
  markerOverviewExtendedContent2?: string;
}

export interface NumericalMarkerContent extends NonFSMarkerContent {
  tailoredHeader?: string | null;
  tailoredOverview?: string | null;
  moreResources: ResourceLink[];
}

export interface DescriptiveMarkerContent extends NonFSMarkerContent {
  tailoredHeader?: string | null;
  tailoredOverview?: string | null;
  moreResources: ResourceLink[];
}

export type ResourceLink = {
  name: string;
  link: string;
};

export interface MarkerValueException {
  name: string;
  descriptor: string;
  footerDescriptor?: string;
  value: string;
  severityIndex: number;
}

export type NavItem = {
  label: string;
  url?: string;
  link?: string;
  className?: string;
  dataTest?: string;
  dataAttributes?: object;
  style?: object;
  htmlId: string;
  classsName?: string;
  category?: string;
  items?: NavItem[];
};

export type Consult = {
  available: boolean;
  available_until: string;
};

export type ShippingAddress = {
  id: number;
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  city: string;
  state: number | string;
  zipCode: number;
};

export type Notification = {
  id?: string;
  message: string;
  source: string;
  type?: ToastType;
  persist?: boolean;
  details?: JSX.Element | string;
  mixpanelLabel?: string;
  onlyShowOnce?: boolean;
};

declare global {
  interface HTMLElement {
    href?: string;
  }
}

export interface AncestryUrlType {
  [key: number]: string;
}

export interface BaseRegistrationInput<T> {
  value: T;
  setValue: (value: T) => void;
}

export interface KitRegistrationInput<T> extends BaseRegistrationInput<T> {
  name: string;
  id: any;
  checked?: boolean;
  required?: boolean;
  validation?: RegExp | { test: (value: string) => boolean };
  invalid?: boolean;
  error?: string;
}

export interface KitRegistrationUser {
  addressCity: KitRegistrationInput<string>;
  addressState: KitRegistrationInput<string>;
  addressZipCode: KitRegistrationInput<string>;
  biologicalSex: KitRegistrationInput<string>;
  congregateSetting: BaseRegistrationInput<string>;
  consentCopy: BaseRegistrationInput<string>;
  consentName: BaseRegistrationInput<string>;
  consentOrganizationName: BaseRegistrationInput<string>;
  consentTimestamp: BaseRegistrationInput<string>;
  covidScreener?: KitRegistrationInput<string>;
  covidVaccineData: BaseRegistrationInput<CovidVaccineData>;
  customTerms: KitRegistrationInput<boolean>;
  dateOfBirth: KitRegistrationInput<string>;
  emailAddress: KitRegistrationInput<string>;
  employerCity: KitRegistrationInput<string>;
  employerName: BaseRegistrationInput<string>;
  employerState: KitRegistrationInput<string>;
  employerStreetAddress: KitRegistrationInput<string>;
  employerSubAddress: KitRegistrationInput<string>;
  employerZipCode: KitRegistrationInput<string>;
  ethnicity: KitRegistrationInput<string>;
  exposure: BaseRegistrationInput<string>;
  firstCovidTest: BaseRegistrationInput<string>;
  firstName: KitRegistrationInput<string>;
  healthcareSetting: BaseRegistrationInput<string>;
  highRisk: BaseRegistrationInput<string>;
  lastName: KitRegistrationInput<string>;
  newYorkEmployment: BaseRegistrationInput<string>;
  newYorkResident: BaseRegistrationInput<string>;
  newYorkSchoolName: BaseRegistrationInput<string>;
  newYorkStudent: BaseRegistrationInput<string>;
  otherVaccineProduct: BaseRegistrationInput<string>;
  overrideAddress: BaseRegistrationInput<boolean>;
  phoneNumber: KitRegistrationInput<string>;
  pregnancyStatus: KitRegistrationInput<string>;
  race: KitRegistrationInput<string>;
  sampleCollectionDate: KitRegistrationInput<string>;
  sampleCollectionTime: KitRegistrationInput<string>;
  schoolCity: KitRegistrationInput<string>;
  schoolState: KitRegistrationInput<string>;
  schoolStreetAddress: KitRegistrationInput<string>;
  schoolSubAddress: KitRegistrationInput<string>;
  schoolZipCode: KitRegistrationInput<string>;
  streetAddress: KitRegistrationInput<string>;
  subAddress: KitRegistrationInput<string>;
  symptoms: BaseRegistrationInput<string>;
  symptomsDate: BaseRegistrationInput<string>;
  textUpdates: KitRegistrationInput<boolean>;
  thirdPartyId: KitRegistrationInput<string>;
  vaccinated: BaseRegistrationInput<string>;
  vaccineDate2: BaseRegistrationInput<string>;
  vaccineDate: BaseRegistrationInput<string>;
  vaccineDoses: BaseRegistrationInput<string>;
  vaccineProduct: BaseRegistrationInput<string>;
  wantsMarketing: KitRegistrationInput<boolean>;
}

export interface CovidRegistration {
  display_symptoms_confirmation: boolean;
  exposure_options: { [key: string]: string };
  symptom_level_options: { [key: string]: string };
  registration_for_covid_rapid: boolean;
  needs_sample_collection_date: boolean;
}

export interface ThirdPartyRegistration {
  regex_format: boolean | string;
  third_party_enabled: boolean;
  third_party_id: string;
  third_party_member_id: string;
  third_party_id_label: string;
}

export interface EnterpriseRegistration {
  collection_types: string[];
  collection_video_links: string[];
  custom_consent_copy: string;
  custom_terms_enabled: boolean;
  custom_terms_copy?: string;
  display_custom_consent_copy: boolean;
  display_logo: boolean;
  disabled_name_edits: boolean;
  disabled_states: string[];
  enabled_states: string[][];
  explicit_consent: boolean;
  enterprise_partner_name: string;
  enterprise_client_name: string;
  minimum_age: number;
  third_party: ThirdPartyRegistration | null;
}

export interface ProductRegistration {
  id: number;
  name: string;
  description: string;
  available_on: string;
  deleted_at: string | null;
  slug: string;
  meta_description: string;
  meta_keywords: string;
  tax_category_id: number | null;
  shipping_category_id: number;
  created_at: string;
  updated_at: string;
  promotionable: boolean;
  meta_title: string;
  discontinue_on: string | null;
  sequence_cache: number;
  subscription: boolean;
  displayable: boolean;
  position: number | null;
  asin: string | null;
  box_image_url: string | null;
  icon_image_url: string | null;
  fulfillment_provider_id: number;
  lab_balance_weight: number | null;
  dropship_provider_id: number | null;
  paypal_product_id: string;
}

export interface RegistrationURL {
  hippa_telehealth_url: string;
  product_consent_url: string;
  terms_and_conditions_url: string;
}

export interface RegistrationAPIUser {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  user_address?: {
    id: number;
    first_name: string;
    last_name: string;
    street1: string;
    street2: string | null;
    city: string;
    state: string;
    country: string;
    zipcode: string;
    phone: string;
  };
}

export interface BarcodeRegistration {
  id: number;
  dash_format: string;
  variant_name: string;
  variant_sku: string;
  group_name: string;
  expire_date: string;
  channel: string;
  days_since_processed: string;
  created_at: string;
  created_by_name: string;
  updated_by_name: string;
  state: string;
  registered_on: string;
  user_name: string | null;
  pwn_order_number: string | null;
  lab_requisition_url: string;
  cancel_reason: string;
  refunded: boolean;
  lab_name: string;
  issues: [];
  auto_register: null;
  enterprise_partner_name: null;
  enterprise_partner_prefixed_display_name: null;
  enterprise_client_name: null;
  enterprise_client_prefixed_display_name: null;
  replacement_order_id: null;
  humana: boolean;
}

interface CollectionType {
  name: string;
}

export interface CollectionDeviceImage {
  name?: string;
  image_url: string;
}

export interface CollectionInstructionType {
  image_url: string;
  description: string;
  alt_text?: string;
}

export interface CollectionTip {
  image_url: string;
  description: string;
}

export interface CollectionMethod {
  name: string;
  collection_type: CollectionType;
  collection_tips: CollectionTip[];
  video_url: string;
  collection_instructions: CollectionInstructionType[];
  collection_device_images: CollectionDeviceImage[];
}

export interface KitRegistrationResponse {
  id: number;
  state: string;
  covid: CovidRegistration | null;
  pre_kit_registration_step: string;
  enterprise: EnterpriseRegistration | null;
  product: ProductRegistration;
  url: RegistrationURL;
  user: RegistrationAPIUser;
  consumer?: RegistrationConsumer;
  barcode: BarcodeRegistration;
  previously_registered_kit: boolean;
  preferences: {
    notification: number;
  };
  collection_types: TestCollectionMethod[];
  collection_methods: CollectionMethod[];
  collection_video_links: string[];
  needs_sample_collection_date: boolean;
  lab_visit?: boolean;
  minimum_age: number;
  gender_restriction: string;
  partner_type: string;
}

export interface RegistrationConsumer {
  id: number;
  dob: string;
  gender: string;
  wants_marketing: boolean;
  age: number;
  race: string | null;
  ethnicity: string | null;
  phone_number: string;
}

export interface KitRegistrationScreener {
  [key: string]: string;
}

export interface KitRegistrationData {
  sample_date: string;
  sample_time: string;
  sample_tz?: string;
  screener?: KitRegistrationScreener;
}

export interface NewYorkCovidData {
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
}

export type AdditionalDose = {
  date: string;
  dose: number;
  name: string | JSX.Element;
};
export interface CovidVaccineData {
  additional_doses?: AdditionalDose[];
  doses?: number;
  first_dose_date?: string;
  name?: string;
  received: string;
  second_dose_date?: string;
  second_dose_name?: string;
}

export interface CovidRegistrationPayload {
  exposure?: string;
  comorbidities?: string;
  congregate_setting?: string;
  COVID_symptoms?: string;
  current_ICU?: string;
  currently_hospitalized?: string;
  employed_healthcare_setting?: string;
  first_COVID_test?: string;
  symptoms_level?: string;
  symptoms_start_date?: string;
  COVID_vaccine?: string;
  COVID_vaccine_data?: CovidVaccineData;
  live_in_ny?: string;
  employed_in_ny?: string;
  student_in_ny?: string;
  employer?: NewYorkCovidData;
  student?: NewYorkCovidData;
}

export interface PatchRegistrationPayload {
  consumer: {
    attributes: {
      first_name: string;
      last_name: string;
      dob: string;
      gender: string;
      race: number | null;
      ethnicity: number | null;
      phone_number: string;
      pregnancy_status?: boolean;
      wants_marketing?: number;
    };
    preference: {
      notification: number;
    };
  };
  spree_address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zipcode: string;
    phone: string;
  };
  kit_registration: {
    serial_number: string;
    consented_at?: string;
    third_party_id?: string;
    confirmed_address?: string;
    data_sharing?: boolean;
    data?: KitRegistrationData & CovidRegistrationPayload;
    consent?: {
      timestamp: string;
      user_name: string;
      copy: string;
      organization_name: string;
    };
  };
}

// Settings

export type AccountSettings = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: string;
  biologicalSex: string;
  wantsMarketing: string;
  canUpdateEmail?: boolean;
};

// Settings - Shipping
export interface ShippingSettings {
  first_name: string;
  last_name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  phone?: number;
}

export type InputError = string | ReactNode;

// Health Profile
export type HealthProfileOption = {
  content: string;
  description?: string;
  free_response: boolean;
  next_questionnaire_question_id: number | null;
  option_id: number;
  position: number;
};

export type HealthProfileQuestion = {
  content: string;
  default_next_questionnaire_question_id: number | null;
  description?: string;
  question_options: HealthProfileOption[];
  questionnaire_question_id: number;
  response?: HealthProfileResponse;
  root: boolean;
  skippable: boolean;
  title: string;
  type: string;
  unanswered: boolean;
};

export type HealthProfileResponseOptions = {
  option_id: number;
};
export type HealthProfileResponse = {
  id: number;
  options: HealthProfileResponseOptions[];
  questionnaire_question_id: number;
  skipped: boolean;
  value: any;
};

export type HealthProfileQuestionnaire = {
  id: number;
  questions: HealthProfileQuestion[];
  has_started: boolean;
};

export type QuestionProps = {
  className?: string;
  questionData: HealthProfileQuestion;
  questionnaireId: number;
};

export type FactCardValues =
  | 'Digestive Disorder'
  | 'Headaches/Migraines'
  | 'Thyroid Disorder';
export type HealthProfileProps = {
  food_sensitivity: string[];
  pcp: boolean | null;

  fact_cards: FactCardValues[];
};

export type Personalizations = {
  health_profile: HealthProfileProps;
};

export type HealthProfileProgressStatus = {
  has_started: boolean;
  progress: number;
  total_questions: number;
  unanswered_questions: number;
  last_interacted_at: string | null;
};

export type HealthProfileProgressStatusResponse = {
  questionnaire: HealthProfileProgressStatus;
};

export type TouchHealthProfileProgressStatus = {
  last_interacted_at: string;
};

export type GroupedMarkers = {
  outOfRange: NumericalResult[];
  inRange: NumericalResult[];
  lab?: Lab;
};

export type NumericalResult = {
  [key: string]: MarkerWithResult[];
};

// PreRegistrationDisclaimer Types
export type PreRegistrationDisclaimerCallToActionType = {
  text: string;
  url: string;
};

export type PreRegistrationDisclaimerType = {
  body: string;
  callToAction: PreRegistrationDisclaimerCallToActionType;
  title: string;
};

// PreRegistrationScreener
export enum PreRegistrationScreenerOptionsValues {
  eligible = 'eligible',
  ineligible = 'ineligible',
}

export type PreRegistrationScreenerOptionType = {
  items: string[] | null;
  label: string;
  value: PreRegistrationScreenerOptionsValues;
};

export type PreRegistrationScreenerType = {
  callToActionText: string;
  options: PreRegistrationScreenerOptionType[] | null;
  title: string;
  description?: string;
};

export type PreRegistrationScreenerAnswer = {
  [key: string]: string;
};

export type ContentSectionType = {
  sectionId: string;
  title: string;
  description: string;
  headline?: string;
  subHeadline?: string;
  image?: {
    title: string;
    url: string;
  };
  modalTitle?: string;
  slug?: string;
};

export type RecommendedTest = {
  id: number;
  name: string;
  box_image_url: string;
  meta_description: string;
  master_variant_id: number;
  price_in_dollars: string;
  slug: string;
  credit_redeemable: boolean;
};

export type LifeStyleRecommendation = {
  name: string;
  description: string;
};

export type Medication = {
  active: boolean;
  comments: string;
  dosage: string;
  end_date: string | null;
  instructions: string;
  name: string;
  start_date: string | null;
};

export type CarePlanDataResponse = {
  recommended_tests: RecommendedTest[];
  visit_summary: string;
  screening_recommendations: string[];
  provider_name: string;
  issued_at: string;
  lifestyle_recommendations: LifeStyleRecommendation[];
  vaccination_recommendations: string[];
  patient_plan: string;
  medications: Medication[];
  lab?: CarePlanLab;
};

export type CarePlanLab = {
  name: string;
  cpt_codes: string[];
};
export class AddressValidationError extends Error {
  suggestions: AddressSuggestion[];
  addressInvalid: boolean;

  constructor(
    message: string,
    suggestions: AddressSuggestion[],
    addressInvalid: boolean,
  ) {
    super(message);

    this.name = 'AddressValidationError';
    this.suggestions = suggestions;
    this.addressInvalid = addressInvalid;
  }
}

//TODO: Update WeightMembershipData types once Weight Membership Data endpoint is available
export type WeightMembershipData = {
  type: string;
  monthlyPrice: string;
  nextBillingDate: string;
  enrolled: string;
  membershipStatus: string;
};

export type SubscriptionPlan = {
  name: string;
  spree_variant_id: number;
  interval: string;
  interval_count: number;
  interval_price: number;
  annual_savings: number;
  description: string;
  promotional_price?: number;
  enrollment_credits_amount: number | null;
};

export type Program = {
  slug: string;
  subscription_plans: SubscriptionPlan[];
};

export type CollectionTypeCategory =
  | 'nasal_swab'
  | 'vaginal_swab'
  | 'stool'
  | 'urine'
  | 'saliva'
  | 'finger_prick';

export type CollectionTypeRedemption = {
  name: string;
  category: CollectionTypeCategory;
};

export type RedeemableProduct = {
  id: number;
  name: string;
  description: string;
  slug: string;
  icon_image_url: string | null;
  meta_description: string;
  master_variant_id: number;
  box_image_url: string | null;
  price_in_dollars: string;
  available_to_redeem: boolean;
  symptoms: string[];
  biomarkers: string[];
  collection_types: CollectionTypeRedemption[];
  type: EnrolledProgram['program_type'] | null;
};

export type RedeemableTier = {
  credits_cost: number;
  products: RedeemableProduct[];
};

export type RedeemableProductsResponse = {
  redeemable_products: RedeemableTier[];
};
